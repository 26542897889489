<template>
  <div class="group-list-container">
    <div class="group-list-area">
      <div class="new-group-btn">
        <a-button class="editable-add-btn" @click="openCreateModal">
          <PlusCircleOutlined />
          {{ t('LM005.create') }}
        </a-button>
      </div>
      <div class="group-list">
        <a-table
          bordered
          :data-source="groupsCount"
          :columns="columns"
          rowKey="groupId"
        >
          <template #tmpTitle="{ text, record }">
            <div class="editable-cell">
              <div
                v-if="editableData[record.groupId]"
                class="editable-cell-input-wrapper"
              >
                <a-input
                  v-model:value="editableData[record.groupId].title"
                  @pressEnter="save(record.groupId)"
                />
                <check-outlined
                  class="editable-cell-icon-check"
                  @click="save(record.groupId)"
                />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ text || ' ' }}
                <edit-outlined
                  class="editable-cell-icon"
                  @click="edit(record.groupId)"
                />
              </div>
            </div>
          </template>
          <template #tmpCreatedTime="{ text }">
            {{ convertTimeFormat(text) }}
          </template>
          <template #operation="{ record }">
            <div class="delete-btn">
              <a-popconfirm
                v-if="groupsCount.length"
                :title="t('LM005.confirmDelete')"
                @confirm="onDelete(record)"
                :okText="t('LM005.confirm')"
                :cancelText="t('LM005.cancel')"
              >
                <DeleteOutlined />
              </a-popconfirm>
            </div>
            <div class="edit-btn">
              <EditOutlined @click="edit(record.groupId)" />
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model:visible="isShowCreateModal"
      :title="t('LM005.createGroup')"
      @ok="handleCreateGroupOk"
      :okText="t('LM005.confirm')"
      :cancelText="t('LM005.cancel')"
    >
      <a-form
        ref="createGroupFormRef"
        :model="createGroupItem"
        :label-col="createGroupLabelCol"
        :wrapper-col="createGroupWrapperCol"
        :rules="createGroupRules"
      >
        <a-form-item :label="t('LM005.groupName')" name="title">
          <a-input v-model:value="createGroupItem.title" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import {
    CheckOutlined,
    EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
  } from '@ant-design/icons-vue'
  import { cloneDeep } from 'lodash-es'
  import dayjs from 'dayjs'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      CheckOutlined,
      EditOutlined,
      PlusCircleOutlined,
      DeleteOutlined,
    },

    setup() {
      const { t } = useI18n()
      const store = useStore()
      let isShowCreateModal = ref(false)
      const createGroupFormRef = ref(null)
      let createGroupLabelCol = ref({ span: 6 })
      let createGroupWrapperCol = ref({ span: 18 })
      let createGroupRules = ref({
        title: [
          {
            required: true,
            message: t('LM005.pleaseInputGroupName'),
            trigger: 'blur',
          },
        ],
      })
      let createGroupItem = ref({ title: '' })
      const columns = [
        {
          title: t('LM005.groupName'),
          dataIndex: 'title',
          width: '40%',
          slots: {
            customRender: 'tmpTitle',
          },
        },
        {
          title: t('LM005.numberOfPerson'),
          dataIndex: 'subjectCount',
        },
        {
          title: t('LM005.createTime'),
          dataIndex: 'created',
          slots: {
            customRender: 'tmpCreatedTime',
          },
        },
        {
          title: '',
          dataIndex: 'operation',
          width: '10%',
          slots: {
            customRender: 'operation',
          },
        },
      ]
      const count = computed(() => groupsCount.value.length + 1)
      const editableData = reactive({})

      const edit = (groupId) => {
        editableData[groupId] = cloneDeep(
          groupsCount.value.filter((item) => groupId === item.groupId)[0]
        )
      }

      const save = async (groupId) => {
        const patchBody = {
          title: editableData[groupId].title,
        }
        try {
          await modifyGroup(groupId, patchBody)
          // Object.assign(
          //   groupsCount.value.filter((item) => groupId === item.groupId)[0],
          //   editableData[groupId]
          // )
          delete editableData[groupId]
        } catch (e) {
          console.log(e)
        }
      }

      const onDelete = (group) => {
        console.log(group.groupId)
        if (group && group.groupId) {
          deleteGroup(group.groupId)
        }
      }

      const openCreateModal = () => {
        createGroupItem.value.title = ''
        isShowCreateModal.value = true
        // const newData = {
        //   groupId: `${count.value}`,
        //   name: ``,
        // }
        // groupsCount.value.push(newData)
      }

      const convertTimeFormat = (text) => {
        if (text) {
          return dayjs(text).format('YYYY/MM/DD')
        } else {
          return ''
        }
      }

      const handleCreateGroupOk = async () => {
        try {
          await createGroupFormRef.value.validate()
          await createGroup(createGroupItem.value)
          isShowCreateModal.value = false
        } catch (e) {
          console.log(e)
        }
      }

      // Vuex action mapping
      const getGroupCountList = () => store.dispatch('group/getGroupCountList')
      const deleteGroup = (groupId) =>
        store.dispatch('group/deleteGroup', groupId)
      const createGroup = (postBody) =>
        store.dispatch('group/createGroup', postBody)
      const modifyGroup = (groupId, patchBody) =>
        store.dispatch('group/modifyGroup', { groupId, patchBody })
      const groupsCount = computed(() => store.state.group.groupsCount)

      onMounted(() => {
        getGroupCountList()
      })

      // watch(count, (count, prevCount) => {
      //   /* ... */
      // })

      return {
        t,
        isShowCreateModal,
        createGroupFormRef,
        createGroupItem,
        createGroupLabelCol,
        createGroupWrapperCol,
        createGroupRules,
        handleCreateGroupOk,
        columns,
        onDelete,
        openCreateModal,
        editableData,
        count,
        edit,
        save,
        groupsCount,
        convertTimeFormat,
      }
    },
  })
</script>

<style lang="less" scoped>
  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .group-list-container {
    background-color: #e5e5e5;
    padding-top: 24px;
    padding-bottom: 24px;

    .group-list-area {
      //margin-top: 24px;
      margin-left: 32px;
      margin-right: 32px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;

      .new-group-btn {
        width: 110px;
        height: 32px;
        margin-top: 20px;
        margin-right: 10px;
        margin-bottom: 20px;
        float: right;
      }

      .group-list {
        .clear-fix-float();

        .delete-btn {
          float: left;
        }
        .edit-btn {
          float: left;
          padding-left: 10px;
        }
      }
    }
  }

  .editable-cell {
    position: relative;
    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
      padding-right: 24px;
    }

    .editable-cell-text-wrapper {
      padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
      position: absolute;
      right: 0;
      width: 20px;
      cursor: pointer;
    }

    .editable-cell-icon {
      margin-top: 4px;
      display: none;
    }

    .editable-cell-icon-check {
      line-height: 28px;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
      color: #108ee9;
    }

    .editable-add-btn {
      margin-bottom: 8px;
    }
  }
  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }
</style>
